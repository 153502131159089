import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IGetPartner, IPartner, IPartnerFilter, IUpdatePartner } from '../models/partner.model';
import { Invoice, InvoicesRoot, response } from '../models/invoice.model';
import {take, tap} from 'rxjs/operators';
import { formatDate } from '@angular/common';
import {ParseToDebugMenu} from '../../debug-menu/service/ParseToDebugMenu';
@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  private readonly partnerAPI: string = environment.partnerapiUrl;
  private readonly partnerBillingAPI: string = environment.billingApiUrl;

  constructor(private http: HttpClient, private ptdm: ParseToDebugMenu ) {}

  getPartners(filterSettings: IPartnerFilter): Observable<IGetPartner> {
    return this.http.get<IGetPartner>(
      this.partnerAPI + 'Partners?PageIndex=' + filterSettings.pageIndex + '&PageSize=' + filterSettings.pageSize + '&Filter.Name=' + filterSettings.filterPartnerName,
    );
  }

  getPartnerSelf(): Observable<IPartner> {
    return this.http.get<IPartner>(this.partnerAPI + 'Partners/Self');
  }

  getPartner(partnerId: IPartner['id']): Observable<IPartner> {
    return this.http.get<IPartner>(
      this.partnerAPI + 'Partners/' + partnerId + '?includeBrandingQuote=' + true + '&includeBrandingLogo=' + true + '&includeBrandingTermsOfService=' + true,
    ).pipe(tap((data) => this.ptdm.setPartner(data)));
  }

  getPartnerLogo(partnerId: IPartner['id']): Observable<any> {
    return this.http.get<any>(this.partnerAPI + 'Partners/' + partnerId + '/Logo');
  }

  getPartnerTermsOfService(partnerId: IPartner['id']): Observable<any> {
    return this.http.get<any>(this.partnerAPI + 'Partners/' + partnerId + '/TermsOfService');
  }

  updatePartnerLogo(partnerId: IPartner['id'], logo: File): Observable<any> {
    const formData: any = new FormData();
    formData.append('Logo', logo, logo.name);

    return this.http.put(this.partnerAPI + 'Partners/' + partnerId + '/Logo', formData, { responseType: 'text' });
  }

  updatePartnerTermsOfService(partnerId: IPartner['id'], tos: File): Observable<any> {
    const formData: any = new FormData();
    formData.append('file', tos, tos.name);

    return this.http.put(this.partnerAPI + 'Partners/' + partnerId + '/TermsOfService', formData, { responseType: 'blob' });
  }

  updatePartner(partnerId: IPartner['id'], partner: IUpdatePartner): Observable<any> {
    return this.http.put<any>(this.partnerAPI + 'Partners/' + partnerId, partner);
  }

  getInvoices(partnerId: IPartner['id']): Observable<response> {
    return this.http.get<response>(this.partnerBillingAPI + '/accounts/' + partnerId + '/invoices');
  }
}
