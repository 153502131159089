import { Injectable } from '@angular/core';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { Auth0Client } from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError, ReplaySubject } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { IRole } from 'src/deprecated/models-old/role/role.model';
import { IPartner } from '../../modules/partner/models/partner.model';

@Injectable({ providedIn: 'root' })
export class AuthServiceOld {
  // Create an observable of Auth0 instance of client
  auth0Client$ = (
    from(
      // createAuth0Client(environment.auth)
      createAuth0Client({
        domain: environment.auth.domain,
        clientId: environment.auth.clientId,
        authorizationParams: environment.auth.authorizationParams,
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        cacheLocation: 'localstorage',
        useCookiesForTransactions: true,
      }),
    ) as Observable<Auth0Client>
  ).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError((err) => throwError(err)),
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap((res) => {
      this.loggedIn = res;
      if (this.loggedIn) {
        this.getRoles();
        this.getPartnerProfile();
      }
    }),
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.handleRedirectCallback())));
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;

  private apiUrl = environment.apiUrl + 'Authenticate';
  private Roles = new ReplaySubject<IRole[]>(1);
  public Partner = new ReplaySubject<IPartner>(1);

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {}

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    // this.getRoles();
    // this.getPartnerProfile();

    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser())),
      tap((user) => this.userProfileSubject$.next(user)),
    );
    // return this.auth0Client$.pipe(
    //   concatMap((client: Auth0Client) => from(client.getUser(options))),
    //   tap(user => this.userProfileSubject$.next(user))
    // );
  }

  localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      }),
    );
    checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
      // If authenticated, response will be user object
      // If not authenticated, response will be 'false'
      this.loggedIn = !!response;
    });
  }

  login(redirectPath = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        openUrl: async (url) => {
          window.location.replace(url);
        },
      });
    });
  }

  handleAuthCallback() {
    // Only the callback component should call this method
    // Call when app reloads after user logs in with Auth0
    let targetRoute: string; // Path to redirect to after login processsed
    const authComplete$ = this.handleRedirectCallback$.pipe(
      // Have client, now call method to handle auth callback redirect
      tap((cbRes) => {
        // Get and set target redirect route from callback results
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() => {
        // Redirect callback complete; get user and login status
        return combineLatest([this.getUser$(), this.isAuthenticated$]);
      }),
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user and login status
    authComplete$.subscribe(([user, loggedIn]) => {
      // Redirect to target route after callback processing
      this.router.navigate([targetRoute]);
    });
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      client.getIdTokenClaims().then((claims) => {
        let protocol = 'https';
        if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
          protocol = 'http';
        }
        const returnTo = `${protocol}://${location.host}`;

        client.logout({
          clientId: environment.auth.clientId,
          openUrl: (url) => {
            if (url === null || url === undefined || url === '') {
              window.location.replace(claims.logout_url);
            } else {
              // window.location.replace(url);
              window.location.replace(url);
            }
          },
          logoutParams: {
            returnTo: returnTo,
            federated: true,
          },
        });
      });
    });
  }

  public getTokenSilently$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) =>
        from(
          client
            .getTokenSilently(options)
            .then((token) => token)
            // temporary fix for: "Login required" error
            .catch((err) => {
              if (err.error === 'login_required' || err.error === 'interaction_required') {
                return client.loginWithRedirect({
                  openUrl: (url) => {
                    window.location.replace(url);
                  },
                });
              }
              throw err;
            }),
        ),
      ),
    );
  }

  // How the heck does this even work, i am not sure.
  // Seems to require the roles part actually be loaded, otherwise this should just allways return false?
  // IDK. It works, i guess so keeping it for backwrods compatability, but should not be used.
  /**
   * @deprecated Please use the hasPermissionObservable
   */
  public hasPermission(inputNode: PermissionNode): boolean {
    let result = false;
    this.Roles.subscribe((roles) => {
      roles.forEach((item) => {
        item.permissions.forEach((node) => {
          if (node == inputNode) {
            result = true;
            return true;
          }
        });
      });

      return false;
    });

    return result;
  }

  public hasPermissionObservable(inputNode: PermissionNode): Observable<boolean> {
    const observable = new ReplaySubject<boolean>(1);
    this.Roles.subscribe((roles) => {
      let result = false;

      roles.forEach((item) => {
        if (item.permissions.findIndex((x) => x == inputNode) > -1) {
          result = true;
        }
      });

      observable.next(result);
    });

    return observable;
  }

  public hasAcceptedCookiesObservable() {
    // check if the decoed token contains metadta of accepted_cookies: true
    return this.auth0Client$.pipe(
      switchMap(async (client: Auth0Client) => {
        const token = await client.getTokenSilently();
        // decode id token using atob
        const tokenData = JSON.parse(atob(token.split('.')[1]));
        return tokenData['user_metadata']['cookie_policies']['analytics'] === true || tokenData['user_metadata']['cookie_policies']['analytics'] === 'true';
      }),
    );
  }

  private getRoles() {
    this.http.get<IRole[]>(this.apiUrl + '/Roles').subscribe((data) => {
      this.Roles.next(data);
    });
  }

  private getPartnerProfile() {
    this.http.get<IPartner>(environment.partnerapiUrl + 'Partners/Self').subscribe((data) => {
      this.Partner.next(data);
    });
  }
}

export enum PermissionNode {
  isAdmin = 0,
  isPartner = 1,
  developer = 2,
  partnerManageCustomCategories = 3,
  partnerManageCustomProducts = 4,
  partnerManageOwnLogins = 5,
  manageMicrosoftSeats = 6,
  superAdmin = 7,
  betaPage = 8,
  customPdfTemplate = 9,
  canResetMFA = 10,
  endCustomerAccounts = 11,
  dropboxManagement = 12,
  infrastructureManagement = 13,
  acronisManagement = 14,
  setCustomerAsHighRisk = 15,
  enableNceConvertionTool = 16,
  ESETManagement = 17,
  HelloSignManagement = 18,
  ExclaimerManagement = 25,
  SPLAManagement = 19,
  KeepItManagement = 20,
  MicrosoftReportRenewals = 21,
  microsoftRobotAccess = 22,
  PowerBIReports = 23,
  BillingData = 24,
  RelocatorPilot = 26,
  ActivityLog = 27,
  LegacyLog = 28,
  MicrosoftTransferRead = 29,
  MicrosoftTransferWrite = 30,
  MicrosoftSubscriptionsRead = 31,
  MicrosoftSubscriptionsCreate = 32,
  MicrosoftSubscriptionsUpdate = 33,
  MicrosoftSubscriptionsUpgrading = 34,
  AzurePlanRead = 35,
  AzurePlanCreate = 36,
  AzurePlanUpdate = 37,
  AzurePlanRoleManagement = 38,
  ImpossibleCloudManagement = 39,
  TwingateManagement = 40,
  MicrosoftTenantRead = 41,
  MicrosoftTenantCreate = 42,
  MicrosoftTenantUpdate = 43,
  MicrosoftTenantDelete = 44,
}
